.p-multiselect-filter-container {
  display: flex;
  order: 1;
}

.p-multiselect-close {
  order: 2;
}

.p-dropdown-items.p-scroller-content {
  width: 100%;
}